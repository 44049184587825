import React from "react";
import Sketch from "react-p5";

const OrbsSketch = () => {
  let frontOrbs = [];
  let backOrbs = [];
  const numBackOrbs = 10;
  const numFrontOrbs = 20;
  const frontSpd = 0.15;
  const backSpd = 0.5;

  const setup = (p5, canvasParentRef) => {
    p5.createCanvas(p5.windowWidth, p5.windowHeight).parent(canvasParentRef);

    for (let i = 0; i < numFrontOrbs; i++) {
      frontOrbs.push(new FrontOrb(p5));
    }

    for (let i = 0; i < numBackOrbs; i++) {
      backOrbs.push(new BackOrb(p5));
    }
  };

  const draw = (p5) => {
    p5.background(34, 40, 49);

    frontOrbs.forEach((orb) => {
      orb.move();
      orb.display();
    });

    backOrbs.forEach((orb) => {
      orb.move();
      orb.display();
    });
  };

  const windowResized = (p5) => {
    p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
  };

  class FrontOrb {
    constructor(p5) {
      this.p5 = p5;
      this.x = p5.random(p5.width);
      this.y = p5.random(p5.height);
      this.diameter = p5.random(20, 60);
      this.xSpeed = p5.random(-frontSpd, frontSpd);
      this.ySpeed = p5.random(-frontSpd, frontSpd);
      this.id = p5.random(0, 2);
      // this.distance = p5.dist(this.x, this.y, mousePos.x, mousePos.y);

      this.adjustPosition();
    }

    adjustPosition() {
      if (this.x < 35) {
        this.x = 35;
      } else if (this.x > this.p5.width - 35) {
        this.x = this.p5.width - 35;
      }

      if (this.y < 35) {
        this.y = 35;
      } else if (this.y > this.p5.height - 35) {
        this.y = this.p5.height - 35;
      }
    }

    move() {
      this.x += this.xSpeed;
      this.y += this.ySpeed;

      if (
        this.x < 0 + this.diameter / 2 ||
        this.x > this.p5.width - this.diameter / 2
      ) {
        this.xSpeed *= -1;
      }
      if (
        this.y < 0 + this.diameter / 2 ||
        this.y > this.p5.height - this.diameter / 2
      ) {
        this.ySpeed *= -1;
      }
    }

    display() {
      this.p5.noStroke();
      this.p5.fill(0, 173, 181);
      this.p5.ellipse(this.x, this.y, this.diameter, this.diameter);
    }
  }

  class BackOrb {
    constructor(p5) {
      this.p5 = p5;
      this.x = p5.random(p5.width);
      this.y = p5.random(p5.height);
      this.diameter = p5.random(10, 40);
      this.xSpeed = p5.random(-backSpd, backSpd);
      this.ySpeed = p5.random(-backSpd, backSpd);
      this.id = p5.random(0, 2);

      this.adjustPosition();
    }

    adjustPosition() {
      if (this.x < 35) {
        this.x = 35;
      } else if (this.x > this.p5.width - 35) {
        this.x = this.p5.width - 35;
      }

      if (this.y < 35) {
        this.y = 35;
      } else if (this.y > this.p5.height - 35) {
        this.y = this.p5.height - 35;
      }
    }

    move() {
      this.x += this.xSpeed;
      this.y += this.ySpeed;

      if (
        this.x < 0 + this.diameter / 2 ||
        this.x > this.p5.width - this.diameter / 2
      ) {
        this.xSpeed *= -1;
      }
      if (
        this.y < 0 + this.diameter / 2 ||
        this.y > this.p5.height - this.diameter / 2
      ) {
        this.ySpeed *= -1;
      }
    }

    display() {
      this.p5.noStroke();
      this.p5.fill(238, 238, 238);
      this.p5.ellipse(this.x, this.y, this.diameter, this.diameter);
    }
  }

  return <Sketch setup={setup} draw={draw} windowResized={windowResized} />;
};

export default OrbsSketch;
