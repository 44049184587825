import InfoContainer from "../components/InfoContainer";

export default function Home() {
  return (
    <div>
      <div id="about-me" style={{ marginTop: "50px" }}>
        <div>
          <h1 style={{ fontSize: "5em" }}>hello</h1>
          <h2 style={{ fontSize: "3em", color: "yellow" }}>i'm alex</h2>
        </div>
        <p>
          I design and develop web-apps, applications and games. My adventure in
          tech has led me to some exciting projects, from language learning apps
          to Game Jams. When I'm not working on a project, you might find me
          making music or tinkering with bits of tech; I'm constantly exploring
          new ways to blend functionality with creativity in what I do.
        </p>
        <div className="blur-panel" />
      </div>
    </div>
  );
}
