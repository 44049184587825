export default function Games() {
  return (
    <div id="project-list" style={{ marginTop: "50px" }}>
      <div className="r-start">
        <a href="https://framesk-p.itch.io/mixos-harmony-hop">
          <div id="about-me" style={{ cursor: "pointer" }}>
            <div>
              <h2 style={{ fontSize: "3em", color: "yellow" }}>
                mixo's harmony hop
              </h2>
            </div>
            <p>
              made in one week for the Big Mode 2023 Game Jam, harmony hop is 2D
              platforming adventure game, built to be played in your web browser
              (desktop only).
            </p>
            <div className="blur-panel" />
          </div>
        </a>
      </div>
    </div>
  );
}
