import { useSpring, easings } from "@react-spring/web";

export default function useCustomAnims() {
  const [swipeFadeSpring, apiSwipeFade] = useSpring(() => ({
    from: { x: 200, opacity: 0 },
    config: { duration: 1000, easing: easings.easeOutCirc },
  }));
  const [pageFadeSpring, apiPageFade] = useSpring(() => ({
    from: { opacity: 0 },
    config: { duration: 500 },
  }));

  const startupAnims = () => {
    apiPageFade.start({
      from: { opacity: 0 },
      to: { opacity: 1 },
    });

    apiSwipeFade.start({
      from: { x: 200, opacity: 0 },
      to: { x: 0, opacity: 1 },
    });
  };

  const pageChangeAnim = (func, page) => {
    apiSwipeFade.start({
      from: { opacity: 1, x: 0 },
      to: { opacity: 0, x: 200 },
      onRest: () => {
        func(page);
        apiSwipeFade.start({
          from: { opacity: 0, x: 200 },
          to: { opacity: 1, x: 0 },
        });
      },
    });
  };

  return { swipeFadeSpring, pageFadeSpring, startupAnims, pageChangeAnim };
}
