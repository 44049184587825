export default function Contact() {
  return (
    <div>
      <div id="about-me" style={{ marginTop: "50px" }}>
        <p style={{ color: "yellow" }}>email</p>
        <p>alexrjoiner@gmail.com</p>
        <div className="blur-panel" />
      </div>
    </div>
  );
}
