export default function Projects() {
  const weblink = "https://www.alxjnr.com";

  return (
    <div id="project-list">
      <div className="r-end">
        <a href={`${weblink}/toki-time`}>
          <div
            id="about-me"
            className="font-smaller"
            style={{ cursor: "pointer" }}
          >
            <div>
              <h2 style={{ fontSize: "3em", color: "yellow" }}>toki time</h2>
            </div>
            <p>
              toki time is an educational application with the goal of teaching
              its users the language 'toki pona'.
            </p>
            <div className="blur-panel" />
          </div>
        </a>
      </div>
      <div className="r-start">
        <a href={`${weblink}/harmony-hub`}>
          <div id="about-me" className="font-smaller">
            <div>
              <h2 style={{ fontSize: "3em", color: "yellow" }}>harmony hub</h2>
            </div>
            <p>
              harmony hub is a platform for music theory practice and education,
              with numerous activities for practice and practical use. harmony
              hub serves as my personal practice and compositional tool.
            </p>
            <div className="blur-panel" />
          </div>
        </a>
      </div>
      <div className="r-end">
        <a href={`${weblink}/cast-quest`}>
          <div
            id="about-me"
            className="font-smaller"
            style={{ cursor: "pointer" }}
          >
            <div>
              <h2 style={{ fontSize: "3em", color: "yellow" }}>cast quest</h2>
            </div>
            <p>
              cast quest is a wordle-inspired film trivia game. find the link
              between two stars chosen at random.
            </p>
            <div className="blur-panel" />
          </div>
        </a>
      </div>
    </div>
  );
}
