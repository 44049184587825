// src/App.js
import React, { useEffect, useState } from "react";
import "./App.css";
import { CurrentPageContext } from "./context/CurrentPageContext";
import Home from "./pages/Home";
import { animated } from "@react-spring/web";
import Sketch from "./components/SketchBackground";
import useCustomAnims from "./globals/useCustomAnims";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";
import Games from "./pages/Games";

function App() {
  const { swipeFadeSpring, pageFadeSpring, startupAnims, pageChangeAnim } =
    useCustomAnims();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageHeader, setPageHeader] = useState(0);
  const pages = [<Home />, <Projects />, <Contact />, <Games />];

  useEffect(() => {
    startupAnims();
  }, []); // Run the animation when the component mounts

  function changePage(num) {
    setPageHeader(num);
    pageChangeAnim(setCurrentPage, num);
  }

  return (
    <CurrentPageContext.Provider value={{ currentPage, setCurrentPage }}>
      <animated.div style={{ ...pageFadeSpring, overflow: "hidden" }}>
        <Header changePage={changePage} pageHeader={pageHeader} />
        <animated.div style={{ ...swipeFadeSpring }}>
          {pages[currentPage]}
        </animated.div>
      </animated.div>
    </CurrentPageContext.Provider>
  );
}

function Header({ changePage, pageHeader }) {
  const clr = "yellow";

  return (
    <div id="header" style={{ marginTop: "50px" }}>
      <div id="nav">
        <p
          onClick={() => changePage(0)}
          style={{
            color: pageHeader == 0 ? clr : "white",
            backgroundColor: pageHeader == 0 ? "black" : "",
          }}
        >
          about me
        </p>
        <p
          onClick={() => changePage(1)}
          style={{
            color: pageHeader == 1 ? clr : "white",
            backgroundColor: pageHeader == 1 ? "black" : "",
          }}
        >
          projects
        </p>
        <p
          onClick={() => changePage(3)}
          style={{
            color: pageHeader == 3 ? clr : "white",
            backgroundColor: pageHeader == 3 ? "black" : "",
          }}
        >
          games
        </p>
        <p
          onClick={() => changePage(2)}
          style={{
            color: pageHeader == 2 ? clr : "white",
            backgroundColor: pageHeader == 2 ? "black" : "",
          }}
        >
          contact
        </p>
      </div>
    </div>
  );
}

export default App;
